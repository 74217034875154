import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import useStyles from './style';

const ProfilePageSkeleton = () => {
    const styles = useStyles();
    return (
        <div className={classNames(styles.skeletonContainer)}>
            <Skeleton className={styles.skeleton} variant="rect" width="100%" height={300} animation="wave" />

            <Skeleton className={styles.skeleton} variant="rect" width="100%" height={18} animation="wave" />
            <Skeleton className={styles.skeleton} variant="rect" width="100%" height={18} animation="wave" />
            <Skeleton className={styles.skeleton} variant="rect" width="100%" height={18} animation="wave" />
        </div>
    );
};

export default ProfilePageSkeleton;
