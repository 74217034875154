import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    BLUE_LIGHT,
} from '@theme_color';

export default makeStyles(() => ({
    container: {
        position: 'relative',
        '& .MuiDialog-paperScrollPaper': {
            maxHeight: 'calc(100% - 140px);',
        },
        '& .MuiPaper-root': {
            background: 'transparent',
            boxShadow: 'none',
        },
    },
    modalHeader: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'end',
        padding: '0',
    },
    icon: {
        cursor: 'pointer',
        color: BLUE_LIGHT,
    },
    skeletonContainer: {
        padding: '16px',
        width: '100%',
    },
    skeletonField: {
        marginBottom: '24px',
    },
    skeleton: {
        marginTop: '6px',
    },
}));
