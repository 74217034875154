/* eslint-disable no-unused-expressions */
import { getCmsBlocks } from '@modules/theme/services/graphql';
import ModalPromoView from './view';

const ModalPromo = () => {
    const headerConfig = {
        headerTitle: 'asa',
        header: 'relative',
        headerBackIcon: 'close',
    };

    const { data, loading, error } = getCmsBlocks({ identifiers: ['weltpixel_newsletter_v1'] });

    const content = data
        && data.cmsBlocks
        && data.cmsBlocks.items
        && data.cmsBlocks.items[0]
        && data.cmsBlocks.items[0].content;

    if (content) {
        return (
            <ModalPromoView
                loading={loading}
                error={error}
                headerConfig={headerConfig}
                content={content}
            />
        );
    }

    return null;
};

export default ModalPromo;
