/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Cancel';
import Cookies from 'js-cookie';
import { newsLetterExpiredTime } from '@root/swift.config';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';
import parse from 'html-react-parser';
import Head from 'next/head';
import useStyle from './style';
import Skeleton from './Skeleton';

const imgUrl = (url) => `${getThumborUrl()}/unsafe/0x0/filters:format(webp):quality(90)/${url}`;

const ModalPromo = (props) => {
    const { loading, error, content } = props;

    const [showModal, setShowModal] = React.useState(!Cookies.get('newsletter_closed'));

    const handleModal = () => {
        Cookies.set('newsletter_closed', true, { expires: Number(newsLetterExpiredTime) });
        setShowModal(!showModal);
    };

    const styles = useStyle();

    const options = {
        replace: ({ name, attribs }) => {
            if (name === 'img') {
                return (
                    <>
                        <Head>
                            <link rel="preload" as="image" href={imgUrl(attribs.src)} />
                        </Head>
                        <img src={imgUrl(attribs.src)} height={384} width={384} alt={attribs.src} data-pagespeed-no-defer loading="eager" />
                    </>
                );
            }
        },
    };

    return (
        <>
            <Dialog open={showModal} maxWidth="sm" className={styles.container} fullWidth={loading}>
                <div className={styles.modalHeader}>
                    <div className={styles.iconWrapper}>
                        <CloseIcon className={styles.icon} onClick={() => handleModal()} />
                    </div>
                </div>
                <div>
                    {loading || error ? <Skeleton /> : (
                        <div className={styles.content}>
                            {parse(content, options)}
                            {' '}
                        </div>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default ModalPromo;
